<!--
 * @Description:底部我的-关于我们-用户协议 userAgreement
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-27 15:19:07
 * @LastEditors: zhoucheng
-->
<!--  -->
<template>
  <div class='main'>
    <van-nav-bar title="用户协议"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div style="padding:0 10px">
      <h1 style="text-align:center">《用户使用协议》</h1>
      <div style="text-indent:2em">
        1. 服务条款的确认
      </div>
      <div style="text-indent:2em">
        请务必认真阅读和理解本《使用协议》（以下简称《协议》）中规定的所有权利和限制。除非您接受本《协议》条款，否则您无权注册、登陆或使用本协议所涉及的相关服务。您一旦注册、登陆、使用或通过任何方式使用本《协议》所涉及的相关服务的行为将视为对本《协议》的接受，即表示您同意接受本《协议》各项条款的约束。如果您不同意接受本《协议》中的条款，请不要注册、登陆或使用本《协议》的相关服务。
      </div>
      <div style="text-indent:2em">
        2. 用户的账号、密码及安全性
      </div>
      <div style="text-indent:2em">
        您一旦注册成为我们的用户，您将得到一个账号及对应密码。如果因为您未保管好自己的账号密码而对您、渝悦停或第三方造成的损害，您将负全部责任。此外，您将对自身账户中所有活动和事件负全责。您可以随时改变您的密码，也可以结束旧账户重开新账户。用户同意将对发现的任非法使用账户行为和安全漏洞告知渝悦停。
      </div>
      <div style="text-indent:2em">
        3. 服务内容
      </div>
      <div style="text-indent:2em">
        渝悦停手机客户端的所有权和运作权归渝悦停所有。所提供的服务必须按照其发布的公司章程、服务条款和操作规则严格执行。
      </div>
      <div style="text-indent:2em">
        通过渝悦停手机客户端可以在线查找停车信息、停车操作、预约、充值、缴费及对提供之服务发表意见等。除非本协议另有规定，渝悦停对网络服务不承担任何责任，即用户对网络服务的使用承担风险。 渝悦停不保证服务一定满足用户使用要求，也不保证服务不会受中断，对服务的及时性、安全性、准确性也不作担保。
      </div>
      <div style="text-indent:2em">
        4. 服务变更、中断或终止
      </div>
      <div style="text-indent:2em">
        以下情况渝悦停有权在告知用户后中断或终止对用户提供本协议所描述的网络服务而无需对用户或任何第三方承担责任：
      </div>
      <div style="text-indent:2em">
        1、用户提供的个人资料不真实
      </div>
      <div style="text-indent:2em">
        2、用户违反本协议中规定的使用规则
      </div>
      <div style="text-indent:2em">
        3、用户其他违反法律之行为
      </div>
      <div style="text-indent:2em">
        同时，渝悦停保留依据自主判断在将来任何时间变更、修改、增加和删除本服务协议条款的权利。所有修改的协议均构成本协议的组成部分。渝悦停有权随时对协议条款进行修改，一旦协议内容发生变动，渝悦停将会在官方网站或APP公布修改之后的协议内容，该公布行为视为渝悦停已经通知用户修改内容。用户使用协议所提供之服务时应接受协议相关的规则和说明，且此规则和说明均构成本服务条款的一部分。如用户不接受协议所涉及的规则及相关说明，可以主动取消已获得的服务。
      </div>
      <div style="text-indent:2em">
        5. 使用规则
      </div>
      <div style="text-indent:2em">
        1、用户应遵守中华人民共和国有关法律、法规，特别是《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》等计算机和互联网的法律法规和实施办法，用户须承担一切因用户的行为直接或间接引起的法律责任。在任何情况下，渝悦停认为用户的行为可能违反国家法律、法规，可以在任何时候不经事先通知终止向该用户提供服务。
      </div>
      <div style="text-indent:2em">
        2、用户必须提供准确个人资料，如资料有任何变动，必须在三个工作日内更新。
      </div>
      <div style="text-indent:2em">
        3、用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用或存在其它安全问题等情况，应立即通知渝悦停管理员。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，渝悦停不承担任何责任。 如用户违反相关法律法规和本协议各项规定，渝悦停有权不经通知删除该帐号，并停止为该用户提供相关网络服务。
      </div>
      <div style="text-indent:2em">
        4、用户注册成功后，视为允许渝悦停APP通过邮件或其他方式向用户发送相关优惠服务信息。
      </div>
      <div style="text-indent:2em">
        6. 隐私保护
      </div>
      <div style="text-indent:2em">
        用户在遵守上述协议的前提下，渝悦停将充分保护用户享有个人隐私。渝悦停会对用户的个人信息严格保密，不会在未经合法用户授权时，公开、编辑或透露其个人信息及保存在渝悦停中的非公开内容，下列情况除外：
      </div>
      <div style="text-indent:2em">
        1、违反相关法律法规或渝悦停充电服务协议规定；
      </div>
      <div style="text-indent:2em">
        2、按照相关政府主管部门的要求，有必要向相关法律部门提供备案的内容；
      </div>
      <div style="text-indent:2em">
        3、维护社会个体和公众的权利、财产或人身安全；
      </div>
      <div style="text-indent:2em">
        4、被侵害的第三人提出权利主张；
      </div>
      <div style="text-indent:2em">
        5、为维护用户及社会公共利益的需要；
      </div>
      <div style="text-indent:2em">
        6、维护本网站的合法权益；
      </div>
      <div style="text-indent:2em">
        7、事先获得用户的明确授权；
      </div>
      <div style="text-indent:2em">
        8、符合其它相关的要求。
      </div>
      <div style="text-indent:2em">
        当渝悦停与第三方合作向用户提供相关的网络服务情况下，如第三方承诺严格承担与渝悦停同等的保护用户隐私的责任，则视为客户授权渝悦停将包含个人注册资料在内的相关信息权提供给该等第三方。
      </div>
      <div style="text-indent:2em">
        7. 免责声明
      </div>
      <div style="text-indent:2em">
        1、本服务涉及到互联网及移动通讯等服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、GSM网络、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。使用本服务的用户须承担以上风险，渝悦停对服务之及时性、安全性、准确性不作担保，对因此导致用户不能发送和接受信息、或传递错误，个人设定之时效、未予储存或其它问题不承担任何责任。对于不可抗力或渝悦停过错原因导致的用户数据损失、丢失或服务停止，渝悦停将不承担任何责任。
      </div>
      <div style="text-indent:2em">
        2、渝悦停无法对用户的使用行为进行全面控制，因此对用户不作任何类型的担保。用户同意渝悦停的使用承担全部风险。用户使用经由本服务下载的或取得的任何资料、信息而导致该用户系统损坏或资料流失等风险自行负担。
      </div>
      <div style="text-indent:2em">
        3、渝悦停客户端内设有通往其他网站和网页的链接，但这些网站和网页并非渝悦停经营或控制，渝悦停对其正确性、合法性、正当性不负任何责任。用户启动任何此类链接或网页，所有风险自负；因使用或依赖上述网站或资源所产生的损失或损害，渝悦停不承担任何责任。
      </div>
      <div style="text-indent:2em">
        4、用户同意保障和维护渝悦停及其他用户的利益，如用户使用该服务导致渝悦停或任何其他第三人造成损失等，用户同意承担一切责任。渝悦停对用户使用网络服务所产生的任何直接、间接、偶然、特殊及继起的损害不负任何责任。
      </div>
      <div style="text-indent:2em">
        5、任何单位或个人认为渝悦停的内容可能涉嫌侵犯其合法权益，应该及时向渝悦停书面反馈，并提供有效身份证明、权属证明及详细侵权情况证明，在收到上述法律文件后，渝悦停将会尽快移除被控侵权内容。
      </div>
      <div style="text-indent:2em">
        8. 知识产权
      </div>
      <div style="text-indent:2em">
        渝悦停APP提供的服务中包含的任何文本、图片、图形、音频和视频资资料均受版权、商标、和其他财产所有权法律的保护，未经相关权利人的同意，上述资料均不得在任何媒体直接或者间接发布、播放、出于播放或发布目的而改写或者在发行，或者被用于其他任何商业目的。所有这些资料或者资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。渝悦停提供服务而使用的任何软件(包括但不限于软件中所含的任何图像、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料)的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程、反向编译或反汇编。
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>
